<script lang="ts">
	import { googleSession, keycloak, logoutSession } from '$lib/stores/userstore';
	import { ga } from '@beyonk/svelte-google-analytics';
	import { LINK_EXTERNAL_WALLET_MODEL_REMINDER_KEY } from '../../../constants';
	import { revokeGoogleAccessToken } from '$lib/service/AxiosService';

	let logout = async () => {
		if (!!$keycloak) {
			sessionStorage.removeItem(LINK_EXTERNAL_WALLET_MODEL_REMINDER_KEY);
			ga.addEvent('WEB_LOG_OUT', 'LOGOUT');
			try {
				await revokeGoogleAccessToken($googleSession)
				logoutSession();
			} catch (error) {
				console.log('error signing out: ', error);
			}
		}
	};
</script>

<div class="absolute z-40 px-5 py-2 mt-4 menu-position">
	<ul class="space-x-3 item-container">
		<li
			role="menuitem"
			tabindex="0"
			data-test-id="logout"
			on:keypress
			on:click={logout}
			class="font-medium text-white cursor-pointer"
		>
			<div class="flex items-center transform transition-colors duration-200 py-4 px-6">
				<div class="mr-3">
					<svg
						class="w-6 h-6"
						fill="none"
						stroke="currentColor"
						viewBox="0 0 24 24"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							stroke-linecap="round"
							stroke-linejoin="round"
							stroke-width="2"
							d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
						/>
					</svg>
				</div>
				Logout
			</div>
		</li>
	</ul>
</div>

<style>
	.menu-position {
		top: 4rem;
		right: 0px;
	}

	.item-container {
		border-radius: 6px;
		border: 1px solid var(--popup-border, #2d2d2d);
		background: var(
			--gray-20070,
			linear-gradient(135deg, rgba(57, 57, 57, 0.7) 0%, rgba(40, 40, 40, 0.7) 100%)
		);
		/* black_sh_bg_blur */
		box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.2);
		backdrop-filter: blur(8px);
	}
</style>
