<script lang="ts">
	import { browser } from '$app/environment';
	import { beforeNavigate } from '$app/navigation';
	import { page } from '$app/stores';
	import { CloseIcon, MobileSideBarIcon } from '$lib/components/icons';
	import DefaultProfileIcon from '$lib/components/icons/DefaultProfileIcon.svelte';
	import DisplayIcon from '$lib/components/icons/DisplayIcon.svelte';
	import { copyAdreessToClipboard, genShortAddress } from '$lib/service/WalletManager';
	import { selectedAccount } from '$lib/stores/connectstore';
	import { scrollPosition } from '$lib/stores/scrollstore';
	import { userInfo } from '$lib/stores/userstore';
	import SmallWalletConnect from '../wallet/SmallWalletConnect.svelte';
	import NavItem from './NavItem.svelte';
	import logo from './logo.png';

	export let dark: boolean;

	let props: any = {};

	let showMenu = false;
	$: showMenuCSS = showMenu ? 'bg-black top-0 w-full h-screen absolute z-30' : 'hidden';
	function showMobileMenu() {
		showMenu = !showMenu;
	}

	if (browser) {
		beforeNavigate(() => {
			showMenu = false;
		});
	}

	$: shotAddress = genShortAddress($selectedAccount);

	$: scrollHeaderClass =
		$scrollPosition && $scrollPosition.scrollTop > 0
			? 'header-container-scroll'
			: 'header-container';
</script>

<header
	class:dark
	class={`flex content-start shrink-0 h-20 lg:h-[90px] sticky top-0 z-30 ${scrollHeaderClass}`}
>
	<div class="flex w-full pr-4 lg:pr-0" {...props}>
		<div class="flex align-baseline h-20 lg:h-[90px] px-4 lg:px-6">
			<a class="flex items-center justify-center h-full w-full" href="http://fortress-arena.io">
				<img class="object-contain h-11 lg:h-16" src={logo} alt="Fortress Arena" />
			</a>
		</div>
		<div class="flex flex-grow self-stretch" />
		<nav
			class="{showMenuCSS} items-center lg:items-start p-2 lg:p-0 lg:flex flex-row justify-start"
		>
			{#if $userInfo}
				<div
					class="flex flex-row-reverse lg:hidden text-white justify-self-end"
					on:click={showMobileMenu}
					on:keypress
					role="button"
					tabindex="0"
				>
					<DisplayIcon
						color="currentColor"
						size={30}
						viewBoxHeight={24}
						viewBoxWidth={24}
						icon={CloseIcon}
					/>
				</div>
				<div class="flex lg:hidden justify-center items-center text-white flex-col">
					<DefaultProfileIcon width={32} height={32} />
					<div class="flex text-sm mt-2">
						<div class="flex font-semibold">@{name}</div>
					</div>
					<div class="flex text-base font-normal mt-2">
						<div class="flex">{shotAddress}</div>
						<div
							class="flex ml-2 p-1"
							on:click={() => copyAdreessToClipboard($selectedAccount)}
							on:keypress
							role="button"
							tabindex="0"
						>
							<svg
								width="15"
								height="14"
								viewBox="0 0 15 14"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M8.8333 13.6666H2.16663C1.98988 13.6728 1.81375 13.6425 1.64921 13.5777C1.48466 13.5128 1.33521 13.4148 1.21016 13.2898C1.0851 13.1647 0.987107 13.0153 0.922275 12.8507C0.857442 12.6862 0.827155 12.51 0.833297 12.3333V5.66663C0.827155 5.48988 0.857442 5.31375 0.922275 5.14921C0.987107 4.98466 1.0851 4.83521 1.21016 4.71016C1.33521 4.5851 1.48466 4.48711 1.64921 4.42227C1.81375 4.35744 1.98988 4.32716 2.16663 4.3333H4.8333V1.66663C4.82716 1.48988 4.85744 1.31375 4.92227 1.14921C4.98711 0.984663 5.0851 0.835214 5.21016 0.710157C5.33521 0.585099 5.48466 0.487107 5.64921 0.422275C5.81375 0.357442 5.98988 0.327155 6.16663 0.333297H12.8333C13.01 0.327155 13.1862 0.357442 13.3507 0.422275C13.5153 0.487107 13.6647 0.585099 13.7898 0.710157C13.9148 0.835214 14.0128 0.984663 14.0777 1.14921C14.1425 1.31375 14.1728 1.48988 14.1666 1.66663V8.3333C14.1727 8.51002 14.1423 8.6861 14.0775 8.85061C14.0126 9.01511 13.9146 9.16452 13.7896 9.28955C13.6645 9.41459 13.5151 9.51258 13.3506 9.57745C13.1861 9.64232 13.01 9.67267 12.8333 9.66663H10.1666V12.3333C10.1727 12.51 10.1423 12.6861 10.0775 12.8506C10.0126 13.0151 9.91459 13.1645 9.78955 13.2896C9.66452 13.4146 9.51511 13.5126 9.35061 13.5775C9.1861 13.6423 9.01002 13.6727 8.8333 13.6666ZM2.16663 5.66663V12.3333H8.8333V9.66663H6.16663C5.9899 9.67267 5.81382 9.64232 5.64932 9.57745C5.48482 9.51258 5.33541 9.41459 5.21038 9.28955C5.08534 9.16452 4.98734 9.01511 4.92248 8.85061C4.85761 8.6861 4.82725 8.51002 4.8333 8.3333V5.66663H2.16663ZM6.16663 1.66663V8.3333H12.8333V1.66663H6.16663Z"
									fill="white"
								/>
							</svg>
						</div>
					</div>
				</div>
			{/if}
			<ul
				class="flex flex-col lg:flex-row p-0 m-0 h-80 lg:h-[90px] justify-center items-center list-none bg-contain relative mt-6 lg:mt-0"
			>
				<NavItem text="Home" href="/" pathname={$page.url.pathname} />
				<NavItem text="Marketplace" href="/marketplace" pathname={$page.url.pathname} />
				<NavItem
					disable={!$userInfo}
					text="My Dashboard"
					href="/user/wallet"
					pathname={$page.url.pathname}
				/>
			</ul>
		</nav>
		<div class="hidden lg:flex flex-grow self-stretch" />
		<div class="justify-self-end z-20 hidden lg:flex">
			{#if browser}
				<SmallWalletConnect />
			{/if}
		</div>
		<div
			on:keypress
			role="button"
			tabindex="0"
			class="flex h-20 lg:hidden text-white justify-center items-center mr-1"
			on:click={showMobileMenu}
		>
			<DisplayIcon
				color="currentColor"
				size={24}
				viewBoxHeight={24}
				viewBoxWidth={24}
				icon={MobileSideBarIcon}
				css="flex"
			/>
		</div>
	</div>
</header>

<style>
	.header-container-scroll {
		background: rgba(40, 40, 40, 0.7);
		backdrop-filter: blur(8px);
	}
	.header-container {
		background: rgba(40, 40, 40, 0.3);
		backdrop-filter: blur(8px);
	}
	@media (min-width: 901px) {
		.nav-header {
			background: linear-gradient(
				180deg,
				rgba(23, 24, 24, 0.6) -95.86%,
				var(--primary-900-color) 98.55%
			);
		}
	}

	@media (max-width: 900px) {
		.nav-header {
			background: linear-gradient(
				180deg,
				rgba(23, 24, 24, 0.6) 22.86%,
				var(--primary-900-color) 98.55%
			);
		}
	}
</style>
