<script lang="ts">
	import { AccountService, getName } from '$lib/service/AccountService';
	import { genShortAddress, getKeycloakInstance } from '$lib/service/WalletManager';
	import {
		refreshWebConnectionInfo,
		selectedAccount,
		web3Provider
	} from '$lib/stores/connectstore';
	import {
		activeGameWallet,
		activeWallet,
		googleSession,
		keycloak,
		logoutSession,
		userInfo
	} from '$lib/stores/userstore';
	import type { Account, GoogleAccessToken, Wallet, WalletRequest } from '$lib/types';
	import { ga } from '@beyonk/svelte-google-analytics';
	import {
		Dekey,
		clearLocalData,
		getKeyshareFromLocal,
		getUserIdFromLocal,
		getWalletAddressFromLocal
	} from 'dekey-v2-wallet-core';
	import type { JwtPayload } from 'jwt-decode';
	import { jwtDecode } from 'jwt-decode';
	import { onDestroy, onMount } from 'svelte';
	import { dekeyConfig } from '../../../constants';
	import SecondaryButton from '../button/SecondaryButton.svelte';
	import UserPicture from '../header/UserPicture.svelte';
	import LinkAccountIcon from '../icons/LinkAccountIcon.svelte';
	import Overlay from '../loading/Overlay.svelte';
	import MessageBox from '../model/MessageBox.svelte';
	import { notifications } from '../toast/notifications';

	let keyshare: string | null = null;
	let wasKeyshareOnLocalStorage = false;
	let service: AccountService;
	let wallets: Wallet[] = [];

	let walletCreationInProgress = false;

	async function createGameWallet(
		_googleSession: GoogleAccessToken | undefined,
		userInfo: Account | undefined,
		_wallets: Wallet[]
	) {
		if (walletCreationInProgress) {
			return;
		}
		walletCreationInProgress = true;
		if (!userInfo || !_googleSession) {
			walletCreationInProgress = false;
			return;
		}
		const jwtJSON = jwtDecode<JwtPayload>(_googleSession.id_token);
		// when a different user is logged in, localStorage is needed to be cleaned.
		if (jwtJSON.sub !== getUserIdFromLocal()) {
			clearLocalData();
		} else {
			// If the user already has the keyshare info on localStorage, return it.
			keyshare = getKeyshareFromLocal();
			if (keyshare && _googleSession) {
				walletCreationInProgress = false;
				return;
			}
		}

		wasKeyshareOnLocalStorage = !!keyshare;
		try {
			if (_googleSession && !wasKeyshareOnLocalStorage) {
				const dekey = Dekey.createDekeyInstance(dekeyConfig);
				console.log('_googleSession', _googleSession);
				await dekey.getKeyshare({
					authProvider: 'google',
					saveToLocalStorage: true,
					idToken: _googleSession.id_token,
					accessToken: _googleSession.access_token,
					setProgress: (completed: number) => {}
				});
			}
			try {
				const wallets = await service.wallets(userInfo.email);
				const gameWallet = wallets.find((w) => w.active && w.type === 'internal');
				if (!gameWallet) {
					const gameAddress = getWalletAddressFromLocal();
					if (gameAddress) {
						let w: WalletRequest = {
							address: gameAddress,
							active: true,
							userName: userInfo.email,
							userId: userInfo.username,
							type: 'internal'
						};
						$activeGameWallet = await service.addWallets(w);
						notifications.success('Game wallet is created', 3000);
					}
				}
			} catch (error) {
				console.log('game wallet add: ', error);
			}
			keyshare = getKeyshareFromLocal();
			wasKeyshareOnLocalStorage = !!keyshare;
		} catch (error) {
			console.log('createGameWallet', error);
		} finally {
			walletCreationInProgress = false;
		}
	}

	// $: createGameWallet($googleSession, $userInfo, wallets);

	$: name = getName($userInfo);

	const checkActive = () => {
		if (!!window?.ethereum?.selectedAddress) {
			if (typeof $web3Provider === 'undefined') {
				refreshWebConnectionInfo(window.ethereum);
			}
		}
	};

	$: getKeycloakInstance($googleSession, $keycloak);

	onMount(async () => {
		// Monitor and restart web3 connection if disconnected
		setInterval(checkActive, 2000);
		window.addEventListener('focus', checkActive);

		// load connected web3 account if connected alrady.
		if (window?.ethereum) {
			refreshWebConnectionInfo(window.ethereum);
			window.ethereum.on('accountsChanged', handleAccountsChanged);
		}
	});

	$: if ($userInfo && $keycloak) {
		service = new AccountService($keycloak);
		try {
			service.wallets($userInfo.email).then((resp) => {
				wallets = resp;
			});
		} catch (error) {
			console.log('failed to retrieve wallets registred', error);
			wallets = [];
		}
	}

	onDestroy(() => {
		if (window?.ethereum?.removeListener) {
			window.ethereum.removeListener('accountsChanged', handleAccountsChanged);
		}
		window.removeEventListener('focus', checkActive);
	});

	const handleAccountsChanged = (accounts: string[]) => {
		refreshWebConnectionInfo(window.ethereum);
	};

	$: refreshWallets($selectedAccount, $userInfo);
	async function refreshWallets(address: string | undefined, userInfo: Account | undefined) {
		if (!!address && userInfo && $keycloak) {
			try {
				const service = new AccountService($keycloak);
				wallets = await service.wallets(userInfo.email);
				$activeWallet = wallets.find((w) => w.active && w.type === 'external');

				$activeGameWallet = wallets.find((w) => w.active && w.type === 'internal');
			} catch (error) {
				console.log('refreshWallets', error);
				$activeWallet = undefined;
				$activeGameWallet = undefined;
				wallets = [];
			}
		} else {
			// isExternalWalletRegisted = false;
		}
	}

	let showConflictAccountMsg = false;
</script>

<Overlay show={walletCreationInProgress} />
{#if !!$activeWallet}
	<MessageBox
		open={showConflictAccountMsg}
		title={'Wallet is already linked'}
		message={`Wallet is already '${genShortAddress($selectedAccount)}' connected to '${
			$activeWallet.userName
		}' account. 
		You need to unlink wallet from '${$activeWallet.userName}' account. 
		Are you sure you want logout from the account '${$userInfo?.email}'?`}
	>
		<SecondaryButton
			slot="action"
			text="Logout"
			on:click={async () => {
				showConflictAccountMsg = false;
				ga.addEvent('WEB_LOG_OUT', 'LOGOUT');
				logoutSession();
			}}
		/>
	</MessageBox>
{/if}

<div class="flex items-center pr-5 h-24 relative">
	{#if typeof $googleSession !== 'undefined' && $userInfo}
		<UserPicture {name} />
	{/if}
	{#if typeof $googleSession === 'undefined'}
		<LinkAccountIcon />
	{/if}
</div>
