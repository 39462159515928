<script lang="ts">
	import { genShortAddress } from '$lib/service/WalletManager';
	import { selectedAccount } from '$lib/stores/connectstore';
	import { activeWallet, userInfo } from '$lib/stores/userstore';
	import MetamaskIcon from '../icons/MetamaskIcon.svelte';
	import FontSpan from '../typography/FontSpan.svelte';
	import UserMenu from './UserMenu.svelte';
	import { goto } from '$app/navigation';

	let open: boolean = false;
	export let src: string = '';
	export let name: string;
	export let style: string = '';

	let props: any;
	if (style.length > 0) {
		props.style = style;
	}

	function showMenu() {
		open = true;
	}

	function hideMenu() {
		open = false;
	}
	$: activeClass = open ? 'transform transition duration-300' : '';

	$: shotAddress = genShortAddress($selectedAccount);
</script>

<div tabindex="0" role="menu" on:focus={showMenu} on:mouseenter={showMenu} on:mouseleave={hideMenu}>
	<div
		role="button"
		tabindex="0"
		data-testid="username"
		on:keypress
		on:click={() => {
			goto(`/user/wallet`);
		}}
		class="flex items-center pr-5 h-24 relative"
	>
		<div class="flex flex-row items-center gap-2 cursor-pointer {activeClass}">
			{#if src === ''}
				<div class="w-14 h-14 bg-primary-600 rounded-[6px]" />
			{:else}
				<div class="w-8 h-8 rounded-full overflow-hidden border-2 main">
					<img {src} alt="" class="w-full h-full object-cover" />
				</div>
			{/if}
			<div class="flex flex-col p-2">
				<!-- {#if $activeGameWallet}
					<div class="flex flex-row gap-2">
						<GameWalletIcon />
						<FontSpan varient="body-R-05" class="text-white">
							{genShortAddress($activeGameWallet.address)}
						</FontSpan>
					</div>
				{/if} -->
				{#if $userInfo}
					<div class="flex flex-row gap-2">
						<FontSpan varient="body-R-05" class="text-white">
							{name}
						</FontSpan>
					</div>
				{/if}
				{#if $activeWallet}
					<div class="flex flex-row gap-2">
						<MetamaskIcon />
						<FontSpan varient="body-R-05" class="text-white">
							{genShortAddress($activeWallet.address)}
						</FontSpan>
					</div>
				{/if}
			</div>

			<!-- <div class="flex flex-col">
				<FontSpan varient="body-R-05" class="text-white">{name}</FontSpan>
			</div> -->
		</div>
		{#if open}
			<UserMenu />
		{/if}
	</div>
</div>
