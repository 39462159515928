import { writable } from 'svelte/store';

export const collapsedHeader = writable<boolean>(false);

export const pageEndReached = writable<{} | undefined>();

export const hasMore = writable<{} | undefined>({});

export const scrollPosition = writable<
	{ scrollHeight: number; clientHeight: number; scrollTop: number } | undefined
>();

export const facetpanelSize = writable<number>(0);

export function isDesktop() {
	if (window) {
		const mql = window.matchMedia('(min-width: 901px)');
		return mql.matches;
	}
}

export const FACET_PANEL_FLOAT_SCROLL_TOP = 150;
