<script lang="ts">
	export let text: string;
	export let pathname: string;
	export let href: string;
	export let disable = false;

	$: active = pathname === href;
</script>

<li class="flex items-center h-full py-0 px-4" class:text-secondary-600={active}>
	<a
		class="hover:text-secondary-600 flex h-full items-center px-2
		text-lg no-underline transition-colors hover:no-underline nav-item"
		class:text-secondary-600={active}
		class:text-white={!active}
		class:!text-gray-60={disable}
		class:pointer-events-none={disable}
		data-sveltekit-preload-data="hover"
		{href}
		>{text}
	</a>
</li>

<style>
	.nav-item {
		font-size: 18px;
		font-family: Lato;
		font-style: normal;
		font-weight: 600;
		line-height: 140%;
	}
</style>
