<script lang="ts">
	import { navigating } from '$app/stores';
	import Header from '$lib/components/header/Header.svelte';
	import logo from '$lib/components/header/logo.png';
	import {
		DiscordIcon,
		GitBookIcon,
		Opensea1Icon,
		Opensea2Icon,
		TelegramIcon,
		TwitterIcon
	} from '$lib/components/icons';
	import CircularIcon from '$lib/components/icons/CircularIcon.svelte';
	import DisplayIcon from '$lib/components/icons/DisplayIcon.svelte';
	import Toast from '$lib/components/toast/Toast.svelte';
	import FontSpan from '$lib/components/typography/FontSpan.svelte';
	import { cleanUpMarketplaceSessionStates } from '$lib/stores/localStorageStore';
	import { initCurrencyRates } from '$lib/stores/price';
	import { GoogleAnalytics } from '@beyonk/svelte-google-analytics';
	import '../../app.css';
	import type { LayoutServerData } from './$types';

	export let data: LayoutServerData;

	let dark = true;

	$: cleanUpMarketplaceSessionStates($navigating);

	initCurrencyRates(data.eth, data.matic);
</script>

<GoogleAnalytics properties={['G-783ZRPDYCQ']} />

<Toast />

<div class:dark id="app" class="app">
	<Header bind:dark />

	<main class="flex flex-grow flex-col w-full m-0 my-auto box-border bg-bg">
		<slot />
	</main>

	<footer class="bg-gray-200 flex flex-col justify-center items-center p-14 text-gray-50 gap-10">
		<a class="flex items-center justify-center" href="/">
			<img class="object-contain h-16" src={logo} alt="Fortess Arena" />
		</a>
		<div class="flex gap-6 flex-col items-center">
			<!-- Links are not yet ready -->
			<!-- <div class="flex flex-row gap-10">
					<FontSpan varient="body-R-06" class="text-gray-50">Home</FontSpan>
					<FontSpan varient="body-R-06">Policy</FontSpan>
					<FontSpan varient="body-R-06">Audit Report</FontSpan>
					<FontSpan varient="body-R-06">FAQ</FontSpan>
					<FontSpan varient="body-R-06">Contact Us</FontSpan>
				</div> -->
			<div class="flex">
				<CircularIcon
					on:click={() => {
						window.open('https://discord.gg/Gca78k6THv', '_blank');
					}}
				>
					<DisplayIcon
						color="currentColor"
						size={16}
						viewBoxHeight={12}
						viewBoxWidth={16}
						icon={DiscordIcon}
						css="flex m-3 p-0"
					/>
				</CircularIcon>
				<CircularIcon
					on:click={() => {
						window.open('https://t.me/Fortress_Arena', '_blank');
					}}
				>
					<DisplayIcon
						color="currentColor"
						size={16}
						viewBoxHeight={12}
						viewBoxWidth={18}
						icon={TelegramIcon}
						css="flex m-3 p-0"
					/>
				</CircularIcon>
				<CircularIcon
					on:click={() => {
						window.open('https://twitter.com/Fortress_Arena', '_blank');
					}}
				>
					<DisplayIcon
						color="currentColor"
						size={16}
						viewBoxHeight={12}
						viewBoxWidth={16}
						icon={TwitterIcon}
						css="flex m-3 p-0"
					/>
				</CircularIcon>
				<CircularIcon
					on:click={() => {
						window.open('https://opensea.io/collection/fortress-arena-nft', '_blank');
					}}
				>
					<DisplayIcon
						color="currentColor"
						size={16}
						viewBoxHeight={14}
						viewBoxWidth={16}
						icon={[Opensea1Icon, Opensea2Icon]}
						css="flex m-3 p-0"
					/>
				</CircularIcon>
				<CircularIcon
					on:click={() => {
						window.open(
							'https://atomrigslab.gitbook.io/fortress-arena/getting-started/fortress-arena',
							'_blank'
						);
					}}
				>
					<DisplayIcon
						color="currentColor"
						size={16}
						viewBoxHeight={13}
						viewBoxWidth={21}
						icon={GitBookIcon}
						css="flex m-3 p-0"
					/>
				</CircularIcon>
				<CircularIcon
					on:click={() => {
						window.open('mailto:fortress_cs@atomrigs.io', '_blank');
					}}
				>
					<svg
						width="16px"
						height="16px"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
						class="flex m-3 p-0"
					>
						<path
							d="M4 18L9 12M20 18L15 12M3 8L10.225 12.8166C10.8665 13.2443 11.1872 13.4582 11.5339 13.5412C11.8403 13.6147 12.1597 13.6147 12.4661 13.5412C12.8128 13.4582 13.1335 13.2443 13.775 12.8166L21 8M6.2 19H17.8C18.9201 19 19.4802 19 19.908 18.782C20.2843 18.5903 20.5903 18.2843 20.782 17.908C21 17.4802 21 16.9201 21 15.8V8.2C21 7.0799 21 6.51984 20.782 6.09202C20.5903 5.71569 20.2843 5.40973 19.908 5.21799C19.4802 5 18.9201 5 17.8 5H6.2C5.0799 5 4.51984 5 4.09202 5.21799C3.71569 5.40973 3.40973 5.71569 3.21799 6.09202C3 6.51984 3 7.07989 3 8.2V15.8C3 16.9201 3 17.4802 3.21799 17.908C3.40973 18.2843 3.71569 18.5903 4.09202 18.782C4.51984 19 5.07989 19 6.2 19Z"
							stroke="currentColor"
							stroke-width="1"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
					</svg>
				</CircularIcon>
			</div>
		</div>
		<FontSpan varient="body-R-06">© Copyright 2023 Atomrigs Lab, Inc All Rights Reserved</FontSpan>
	</footer>
</div>

<style>
	.app {
		overflow: auto;
		max-height: 100vh;
		display: flex;
		flex-direction: column;
		flex-shrink: 0;
	}
</style>
