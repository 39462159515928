<script lang="ts">
	import { InfoIcon } from '$lib/components/icons';
	import DisplayIcon from '$lib/components/icons/DisplayIcon.svelte';
	import { flip } from 'svelte/animate';
	import { fly } from 'svelte/transition';

	import { notifications } from './notifications';

	export let themes = {
		danger: '#E26D69',
		success: '#84C991',
		warning: '#f0ad4e',
		info: '#5bc0de',
		default: '#aaaaaa'
	};
</script>

<div
	class="notifications flex flex-col justify-start items-end p-0 left-0 fixed right-3 z-50 top-11"
>
	{#each $notifications as notification (notification.id)}
		<div
			animate:flip
			class=" toast flex rounded items-center justify-items-center"
			style="background: {themes[notification.type]};"
			transition:fly={{ y: 30 }}
		>
			<DisplayIcon size={36} color="#ffffff" css="ml-2 mt-2 text-white" icon={InfoIcon} />
			<div class="content text-white flex p-2">{notification.message}</div>
		</div>
	{/each}
</div>

<style>
	.notifications {
		pointer-events: none;
	}

	.toast {
		flex: 0 0 auto;
	}

	.content {
		font-weight: 500;
	}
</style>
